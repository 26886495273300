export const breadcrumbItems = {
  ports: ["Network Security", "Ports, Services & Protocols"],
  "ssh-credential": ["Network Security", "SSH Authentication Security"],
  "ftp-credential": ["Network Security", "FTP Authentication Security"],
  "email-security": ["Network Security", "Email Security"],
  certificates: ["Network Security", "Certificates"],
  os: ["Servers and Hosts", "OS and Life Of  Indication"],
  "hosting-provider": ["Servers And Hosts", "Hosting Provider"],
  dns: ["Servers And Hosts", "DNS"],
  shodan: ["Servers And Hosts", "Shodan"],
  "login-pages-security": ["Web Applications", "Login Pages Security"],
  "login-page": ["Web Applications", "Login Pages"],
  "headers-and-cookies": ["Web Applications", "Headers And Cookies"],
  "3rd-party-companies": ["Web Applications", "3rd Party Components"],
  waf: ["Web Applications", "WAF"],
  cms: ["Web Applications", "CMS"],
  cdn: ["Web Applications", "CDN"],
  "hosts-security": ["Servers And Hosts", "Hosts Security"],
  "data-leaks": ["Data Exposure", "Data Leaks"],
  "sensitive-exposed-data": ["Data Exposure", "Sensitive Exposed Data"],
  "exposed-documents": ["Data Exposure", "Exposed Documents"],
  "github-exposure": ["Data Exposure", "GitHub Exposure"],
  "pasting-exposure": ["Data Exposure", "Pasting Exposure"],
  "leaked-credentials": ["Data Exposure", "Leaked Credentials"],
  "google-dorking": ["Data Exposure", "Google Dorking"],
  "cloud-exposed-folders": ["Data Exposure", "Cloud Exposed Folders"],
  cves: ["CVEs"],
  "assets-inventory": ["Assets Inventory"],
  offense: ["Offense"],
  "cyber-services": ["Cyber Services"],
};
