export const passAuthFilter = [
  {
    id: 0,
    name: "Yes",
    type: "Password Auth: Yes",
    key: "3",
    active: false,
  },
  {
    id: 1,
    name: "No",
    type: "Password Auth: No",
    key: "3",
    active: false,
  },
];
export const sshAuthFilter = [
  {
    id: 0,
    name: "Secured",
    type: "SSH Auth. Method: Secured",
    key: "4",
    active: false,
  },
  {
    id: 1,
    name: "Unsecured",
    type: "SSH Auth. Method: Unsecured",
    key: "4",
    active: false,
  },
];
export const dictionaryAttackFilter = [
  {
    id: 1,
    name: "Vulnerable",
    type: "Dictionary Attack: Not Vulnerable",
    key: "5",
    active: false,
  },
  {
    id: 0,
    name: "Not Vulnerable",
    type: "Dictionary Attack: Vulnerable",
    key: "5",
    active: false,
  },
];

export const lowEnviornmentFilter = [
  {
    id: 0,
    name: "Yes",
    type: "Low Environment: Yes",
    key: "3",
    active: false,
  },
  {
    id: 1,
    name: "No",
    type: "Low Environment: No",
    key: "3",
    active: false,
  },
];

export const subdomainTakeoverFilter = [
  {
    id: 0,
    name: "Vulnerable",
    type: "Subdomain Takeover: Vulnerable",
    key: "4",
    active: false,
  },
  {
    id: 1,
    name: "Not Vulnerable",
    type: "Subdomain Takeover: Not Vulnerable",
    key: "4",
    active: false,
  },
];