export const impacts = [
  {
    id: 0,
    name: "0-40",
    type: "Impact: 0-40",
    key: "4",
    active: false,
  },
  {
    id: 1,
    name: "40-80",
    type: "Impact: 40-80",
    key: "4",
    active: false,
  },
  {
    id: 2,
    name: "80-100",
    type: "Impact: 80-100",
    key: "4",
    active: false,
  },
];
export const Exploitabilities = [
  {
    id: 0,
    name: "0-40",
    type: "Exploitability: 0-40",
    key: "5",
    active: false,
  },
  {
    id: 1,
    name: "40-80",
    type: "Exploitability: 40-80",
    key: "5",
    active: false,
  },
  {
    id: 2,
    name: "80-100",
    type: "Exploitability: 80-100",
    key: "5",
    active: false,
  },
];
export const CVSS = [
  {
    id: 0,
    name: "0-4",
    type: "CVSS: 0-4",
    key: "6",
    active: false,
  },
  {
    id: 1,
    name: "4-8",
    type: "CVSS: 4-8",
    key: "6",
    active: false,
  },
  {
    id: 2,
    name: "8-10",
    type: "CVSS: 8-10",
    key: "6",
    active: false,
  },
];
export const EPSS = [
  {
    id: 0,
    name: "0.0-0.4",
    type: "EPSS: 0.0-0.4",
    key: "7",
    active: false,
  },
  {
    id: 1,
    name: "0.4-0.8",
    type: "EPSS: 0.4-0.8",

    key: "7",
    active: false,
  },
  {
    id: 2,
    name: "0.8-1",
    type: "EPSS: 0.8-1",
    key: "7",
    active: false,
  },
];
export const scores = [
  {
    id: 0,
    name: "0-39",
    type: "Risk Score: 0-39",
    key: "8",
    active: false,
  },
  {
    id: 1,
    name: "40-79",
    type: "Risk Score: 40-79",
    key: "8",
    active: false,
  },
  {
    id: 2,
    name: "80-100",
    type: "Risk Score: 80-100",
    key: "8",
    active: false,
  },
];

export const hasCveFilterOptions = [
  {
    id: 0,
    name: "Yes",
    type: "Has CVE: Yes",
    key: "9",
    active: false,
  },
  {
    id: 1,
    name: "No",
    type: "Has CVE: No",
    key: "9",
    active: false,
  },
];

export const serviceMap = [
  { id: 3, serviceName: "Ports, Services & Protocols" },
  { id: 16, serviceName: "SSH Authentication Security" },
  { id: 17, serviceName: "FTP Authentication Security" },
  { id: 6, serviceName: "Email Security" },
  { id: 5, serviceName: "Certificates" },
  { id: 13, serviceName: "OS and EndOflife Indication" },
  { id: 7, serviceName: "Hosting Provider" },
  { id: 10, serviceName: "DNS" },
  { id: 12, serviceName: "Shodan" },
  { id: 21, serviceName: "Login Pages Security" },
  { id: 15, serviceName: "Login Pages" },
  { id: 0, serviceName: "Headers and Cookies" },
  { id: 6, serviceName: "3rd Party components" },
  { id: 8, serviceName: "WAF" },
  { id: 9, serviceName: "CMS" },
  { id: 9, serviceName: "CDN" },
  { id: 19, serviceName: "Data Leaks" },
  { id: 22, serviceName: "Sensitive Exposed Data" },
  { id: 12, serviceName: "Exposed Documents" },
  { id: 17, serviceName: "Github Exposure" },
  { id: 21, serviceName: "Pasting Exposure" },
  { id: 19, serviceName: "Leaked Credentials" },
  { id: 18, serviceName: "Subdomain Takeover" },
  { id: 25, serviceName: "Hosts Security" },
];

export const openColorList = ["#B694FF", "#5BD5D5", "#367BD5", "#D9D9D9"];
export const historycolorList = ["#3CE2C1", "#F3E587", "#A7A7B1"];

export const criticalityFilterList = [
  {
    id: 0,
    name: "Low",
    type: "Critical Asset: Low",
    key: "14",
    active: false,
  },
  {
    id: 1,
    name: "Medium",
    type: "Critical Asset: Medium",
    key: "14",
    active: false,
  },
  {
    id: 2,
    name: "High",
    type: "Critical Asset: High",
    key: "14",
    active: false,
  },
];

export const cisaKevFilterList = [
  {
    id: 0,
    name: "Yes",
    type: "CISA KEV: Yes",
    key: "17",
    active: false,
  },
  {
    id: 1,
    name: "No",
    type: "CISA KEV: No",
    key: "17",
    active: false,
  },
];

export const armoryTestFilterList = [
  {
    id: 0,
    name: "Yes",
    type: "Armory Tested: Yes",
    key: "19",
    active: false,
  },
  {
    id: 1,
    name: "No",
    type: "Armory Tested: No",
    key: "19",
    active: false,
  },
];

export const issueStateFilterList = [
  {
    id: 0,
    name: "Open",
    type: "Issue State: Open",
    key: "20",
    active: false,
  },
  {
    id: 1,
    name: "Re-open",
    type: "Issue State: Re-open",
    key: "20",
    active: false,
  },
  {
    id: 2,
    name: "Pending Validation",
    type: "Issue State: Pending Validation",
    key: "20",
    active: false,
  },
];
