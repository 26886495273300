import React, { useState } from "react";
import { BulkReject } from "../bulkReject/BulkReject";
const BulkSelection = ({
  actions,
  onActionSelect,
  multiSelect,
  onClearSelectRow,
  multiselectLabel = "Asset",
  totalRecords = null,
  handleSelectionAll
}) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const toggleBulkDropdown = () => setIsOpenDropdown(!isOpenDropdown);
  const closeDropdown = () => setIsOpenDropdown(false);
  const onSelectAction = (action) => {
    closeDropdown();
    onActionSelect(action);
  };
  const handleSelectAllClick = () => {
    if (!isAllSelected && multiSelect !== totalRecords ) {
      handleSelectionAll(); 
      setIsAllSelected(true); 
    }
  };
  const handleClearAllClick = () => {
    onClearSelectRow(); 
    setIsAllSelected(false); 
  };

  return (
    <div className="d-flex justify-content-center align-items-center text-white p-3 bulk-selection-container mb-3">
      <div className="table-result d-flex align-items-center">
          <p className="text-nowrap m-0 fw-600 fs-16">
            <span
              style={{
                paddingRight: 2,
              }}
            >
              {multiSelect}
            </span>{" "}
            {multiselectLabel} Selected:{" "}
          </p>
          <div className={`clear-all-text mx-4 ${isAllSelected || multiSelect === totalRecords ? "disable-text" : ""}`}  onClick={handleSelectAllClick}>
            Select All ({totalRecords})
          </div>{" "}
          <div className="clear-all-text" onClick={handleClearAllClick}>
            Clear All
          </div>{" "}
          <div className="ms-4 clear-devider" style={{ color: "#F5F5F5" }}>
            |
          </div>
          <BulkReject
            actions={actions}
            onSelectAction={onSelectAction}
            isOpen={isOpenDropdown}
            toggleDropdown={toggleBulkDropdown}
          />
      </div>
    </div>
  );
};

export default BulkSelection;